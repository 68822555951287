exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-buyers-tsx": () => import("./../../../src/pages/info/buyers.tsx" /* webpackChunkName: "component---src-pages-info-buyers-tsx" */),
  "component---src-pages-info-sellers-tsx": () => import("./../../../src/pages/info/sellers.tsx" /* webpackChunkName: "component---src-pages-info-sellers-tsx" */),
  "component---src-pages-promotion-index-tsx": () => import("./../../../src/pages/promotion/index.tsx" /* webpackChunkName: "component---src-pages-promotion-index-tsx" */),
  "component---src-pages-properties-buy-tsx": () => import("./../../../src/pages/properties/buy.tsx" /* webpackChunkName: "component---src-pages-properties-buy-tsx" */),
  "component---src-pages-properties-investment-tsx": () => import("./../../../src/pages/properties/investment.tsx" /* webpackChunkName: "component---src-pages-properties-investment-tsx" */),
  "component---src-pages-properties-luxury-tsx": () => import("./../../../src/pages/properties/luxury.tsx" /* webpackChunkName: "component---src-pages-properties-luxury-tsx" */),
  "component---src-pages-properties-rent-tsx": () => import("./../../../src/pages/properties/rent.tsx" /* webpackChunkName: "component---src-pages-properties-rent-tsx" */),
  "component---src-pages-properties-search-tsx": () => import("./../../../src/pages/properties/search.tsx" /* webpackChunkName: "component---src-pages-properties-search-tsx" */),
  "component---src-pages-property-tsx": () => import("./../../../src/pages/property.tsx" /* webpackChunkName: "component---src-pages-property-tsx" */)
}

